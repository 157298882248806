// export const baseUrl = "http://localhost:8080" // local
export const baseUrl =
            // "https://api-leaderboard.dev.svcs.ferrumnetwork.io"; // dev
            // "https://api-leaderboard.svcs.ferrumnetwork.io" // prod
            // 'https://api-leaderboard.stage.svcs.ferrumnetwork.io';  // staging
            "https://api-gateway-v1.svcs.ferrumnetwork.io" // prod-gateway
            // "https://api-gateway-v1.stage.svcs.ferrumnetwork.io" // staging-gateway
export const apiKeyForApplicationUser = 
            // "42bfdf36-5625-4dbe-9af8-6149cc0a903f"; // staging
                // "b63d9502-1ddd-4ccb-b0bd-e59a3531d7f6"; // dev
            "66fadbfe-b625-48d1-9255-2c6317adf0bf"; // prod
export const tokenFRMBSCMainnet = '0xA719b8aB7EA7AF0DDb4358719a34631bb79d15Dc';
export const tokenFRMxBSCMainnet = '0x8523518001ad5d24b2A04e8729743C0643A316c0';
export const tokenUSDCBSCMainnet = '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d'; 
export const stakingContractAddressListFOMO = ["0x90Bdee77ed081777C12b216f968db706f071b589", "0x3e6b25a764d43ac75dd1d108ffa10eea9f42acbf", "0x6c034b217287b64cb6c6970c1e556045843ee479"];
export const walletAuthenticationBackendURL = "https://alemytxku6.execute-api.us-east-2.amazonaws.com/default/wallet-auth-backend"
export const BSC_api_key = "QFQI7J6GMJXYJW6T5GYNGNNFCWI41S21JI";
export const apiKey = 'ckey_173b82c807954a8697b251305c5:'; 
export const TOKEN_TAG = "tokenV2";
export const ME_TAG = "meV2";
export const ORG_ROLE_TAG = "organizationAdmin";
export const COMMUNITY_ROLE_TAG = "communityMember";
